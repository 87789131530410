// Class generator mixin
@mixin helper-class-generator($selector, $rule, $value) {
  #{$selector} {
    #{$rule}: $value; } }

@include helper-class-generator('.has-line-height-6', line-height, 1);
@include helper-class-generator('.has-line-height-5', line-height, 1.2);
@include helper-class-generator('.has-text-spaceship-grey', color, $text);
@include helper-class-generator('.has-text-strike', text-decoration, line-through);
@include helper-class-generator('.has-standard-basis', flex-basis, 1rem);
@include helper-class-generator('.is-borderless', border, 0);
