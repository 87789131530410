.tall-image {
  flex-grow: 1;
  align-self: stretch;

  display: flex;
  justify-content: center;

  &.tall-image--left {
    flex-direction: row; }

  &.tall-image--right {
    flex-direction: row-reverse; }

  padding: 0 3em; }

.tall-image__image-area {
  // flex-grow: 1
  flex-shrink: 1;
  align-self: stretch;

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; }

.tall-image__comment-section {
  width: 30em;

  .tall-image--left & {
    margin-left: 1em; }

  .tall-image--right & {
    margin-right: 1em; }

  flex-grow: 0;
  flex-shrink: 0;

  display: flex;
  flex-direction: column; }

.tall-image__title {
  font-weight: 700;
  font-size: 1.25em; }

.tall-image__author-row {
  font-size: 0.75em;
  margin-bottom: 0.5em;

  display: flex;
  justify-content: space-between; }
