.album-screen__hero-body {
  background-color: $dark;
  color: $white;

  position: relative;

  display: flex;
  padding: 1rem 0 4rem;

  @media ( max-width: $tablet - 1px ) {
    padding: 0.5rem 0 0.5rem; } }

.album-screen__carousel {
  // border: 1px solid red
  // box-sizing: border-box

  flex-grow: 1;
  align-self: stretch;

  position: relative;

  overflow: hidden; }

.album-screen__image-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  transition: transform $speed * 2;
  transition-timing-function: ease-out;
  touch-action: none;

  &.album-screen__image-container--swiping {
    transition: none; }

  display: flex;
  overflow: hidden;

  background-color: $dark; }

.album-screen__nav-left,
.album-screen__nav-right {
  position: absolute;
  top: 0;

  width: 5em;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  user-select: none;
  cursor: pointer;

  @media ( max-width: $tablet - 1px ) {
    font-size: 0.75em;
    opacity: 0.6;

    width: 3em;
    height: calc(100% - 5em); }

  .album-screen__nav-left-text,
  .album-screen__nav-right-text {
    transition: transform $speed * 4;
    flex-shrink: 0;

    width: 10em;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media ( max-width: $tablet - 1px ) {
      width: 7em; } } }

.album-screen__nav-left {
  left: 0;

  .album-screen__nav-left-text {
    transform: rotate(-90deg) translateY(-1em);

    filter: drop-shadow(0 0 1px $black);

    @media ( max-width: $tablet - 1px ) {
      transform: rotate(-90deg); } }

  &:hover .album-screen__nav-left-text {
    transform: rotate(-90deg) translateY(-1.3em);

    @media ( max-width: $tablet - 1px ) {
      transform: rotate(-90deg); } }

  &, &:hover {
    &.album-screen__nav-left--hidden {
      pointer-events: none;

      .album-screen__nav-left-text {
        transform: rotate(-90deg) translateY(-4em); } } } }

.album-screen__nav-right {
  right: 0;

  .album-screen__nav-right-text {
    transform: rotate(90deg) translateY(-1em);

    filter: drop-shadow(0 0 1px $black);

    @media ( max-width: $tablet - 1px ) {
      transform: rotate(90deg); } }

  &:hover .album-screen__nav-right-text {
    transform: rotate(90deg) translateY(-1.3em);

    @media ( max-width: $tablet - 1px ) {
      transform: rotate(90deg); } }

  &, &:hover {
    &.album-screen__nav-right--hidden {
      pointer-events: none;

      .album-screen__nav-right-text {
        transform: rotate(90deg) translateY(-4em); } } } }

.album-screen__owner-notice {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3rem;

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: bold;
  filter: drop-shadow(0 0 1px $black);

  & > span {
    text-align: center; }

  @media ( max-width: $tablet - 1px ) {
    display: none;
    // flex-direction: column
    // font-size: 0.75em
 } }    // line-height: 1.2

.album-screen__foot-section {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  height: 4rem;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center; }
