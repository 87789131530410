.gallery-image {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 13em;
  padding: 0 0.5em 1em;
  flex-shrink: 0; }

.gallery-image__instant-container {
  padding: 1em 2em;
  height: 12.5em;

  display: flex;
  justify-content: center;
  align-items: center; }

.gallery-image__instant-border {
  border: 1px solid $grey-light;
  padding: 0.5em;

  // transition: border 0.4s

  // &.gallery-image__instant-border--serious
  //   border-color: transparent

  &, &:hover, &:active, &:visited {
    color: $text; }

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background-color: white;

  &.gallery-image__instant-border--vertical {
    flex-direction: row; }

  cursor: pointer;
  transition: transform 0.2s ease-out; }

.gallery-image__favorites {
  font-size: 0.75em; }

.gallery-image__label-container {
  line-height: 1.2;
  text-align: center; }

.gallery-image__title {
  font-weight: bold;
  // height: 3.6em
  overflow: hidden;
  font-size: 0.8em; }

.gallery-image__author {
  font-size: 0.6em;
  height: 1.2em;
  overflow: hidden;
  margin-top: 0.5em; }

.gallery-image__date {
  font-size: 0.6em;
  height: 1.2em;
  overflow: hidden; }
