.wide-image {
  flex-grow: 1;
  align-self: stretch;

  display: flex;
  flex-direction: column; }

.wide-image__image-area {
  flex-grow: 1;
  flex-shrink: 1;

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; }

.wide-image__comment-section {
  height: 21em;
  margin: 1em auto 0;
  flex-grow: 0;
  flex-shrink: 0;

  display: flex;
  flex-direction: column; }

.wide-image__label {
  align-items: flex-end;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  line-height: 1.2;
  margin-bottom: 1em;

  & > div {
    max-width: 17em;
    // max-height: 2.4em
 } }    // overflow: hidden

.wide-image__title {
  font-weight: 700;
  font-size: 1.25em;

  @media ( max-width: 36em ) {
    font-size: 1em; } }

.wide-image__author,
.wide-image__date {
  font-size: 0.75em; }

.wide-image__label-row {
  display: flex;
  justify-content: space-between; }
