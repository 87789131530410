.image-title,
.mobile-image-title {
  align-items: center;
  color: $text-light;
  justify-content: space-between;

  textarea {
    background-color: rgba(0, 0, 0, 0.3);
    border: 0;
    border-radius: 0;
    font-weight: 700;
    font-size: inherit;
    outline: none;
    resize: none;
    width: 100%; } }


.image-title {
  textarea {
    padding: .5em; } }

.mobile-image-title {
  padding-top: .75em;
  padding-bottom: 1em;
  width: 90%;

  textarea {
    padding: 1em 1em 2em;
    text-align: center;
    width: 100%; }

  .icon-text {
    line-height: 1rem;

    span {
      &:first-child {
        padding-left: 2em; }

      &.icon {
        transform: translateY(-.25rem); } } } }
