.app-screen__user-name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 50vw; }

.app-screen__profile-pic {
  font-size: 1.5em;
  margin-right: 0.25em; }

.app-screen__hero-body {
  flex-shrink: 1;
  overflow: scroll;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none; } }

.app-screen__hero {
  height: 100vh;
  height: stretch;
  height: -moz-available;
  height: -webkit-fill-available; }

.app-screen__logo-icon {
  .icon {
    font-size: 1.5em;
    margin-left: 0.25em;
    margin-right: 0.5em !important; } }
