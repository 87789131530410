$family-primary: 'Quicksand', sans-serif;
$text: #51555e;
$dark: #51555e;
$footer-padding: 1em;
$footer-background-color: white;
$radius: .25rem;
$radius-small: .125rem;
$radius-large: .375rem;
// $gap: .25rem
// $tablet: 48rem
// $desktop: 60rem + (2 * $gap)
// $widescreen: 72rem + (2 * $gap)
// $fullhd: 84rem + (2 * $gap)
