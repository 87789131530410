// .hero-body
//   min-height: 30rem

html {
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none; }

  @media ( min-width: 2000px ) {
    font-size: 0.8vw; } }

html, body {
  overscroll-behavior: none; }

* {
  -webkit-tap-highlight-color: transparent; }

// body
//   position: fixed
//   top: 0
//   left: 0
//   width: 100%

.dropdown-content {
  max-height: calc(100vh - 10rem);
  overflow-y: scroll;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none; }

  box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.6); }

.button.dropdown-item {
  &, &:hover, &:active, &:focus, &.is-loading {
    border-radius: 0;
    border: none;
    outline: 0;
    box-shadow: none;
    -moz-appearance: none;
    -webkit-appearance: none; }

  & .icon:first-child:not(:last-child) {
    margin-left: 0; } }

html, body, .root {
  height: 100vh;
  height: stretch;
  height: -moz-available;
  height: -webkit-fill-available; }
