.favorites {
  display: flex;
  align-items: center;

  & > * {
    flex-shrink: 0; }

  height: 2em;

  &.favorites--vertical {
    flex-direction: column;
    height: 100%;
    width: 2em; } }

.favorites__filler {
  flex-grow: 1;
  text-align: center;

  padding: 0 1em;

  &.has-text-right {
    padding-right: 0; } }

.favorites__own {
  font-size: 2em;

  cursor: pointer;

  transition: transform .1s, color .4s;
  transform: scale(1);

  &:hover {
    transform: scale(1.1); }

  &:active {
    transform: scale(1.05); } }

.favorites__own,
.favorites__own:hover,
.favorites__own:active,
.favorites__own:focus,
.favorites__own[disabled],
.favorites__own:focus:not(:active),
.favorites__own.is-focused:not(:active) {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  color: $text;
  display: block;
  height: auto;
  opacity: 1;
  outline: 0;
  padding: 0;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  .favorites--dark-mode & {
    color: $white; } }


.favorites__others {
  align-self: flex-end;

  display: flex;
  align-items: center;

  .favorites--vertical & {
    flex-direction: column;
    align-self: center; } }

.favorites__other-heart {
  margin-right: 0.125em;

  .favorites--vertical & {
    margin-right: 0; } }

.favorites--fun {
  .favorites__own--active,
  .favorites__other-heart {
    color: $danger; } }

.favorites__omitted-hearts {
  font-weight: bold;
  line-height: 1; }

.favorites__comments {
  position: relative;
  font-size: 1.5em;

  &.favorites__comments--empty {
    color: $grey-lighter;

    &.favorites__comments--dark-mode {
      color: $grey-light; } } }

.favorites__comments-blip {
  position: absolute;
  top: 0;
  right: -0.5em;
  font-size: 0.5em;
  font-weight: bold;
  line-height: 1.35;

  background-color: $text;
  color: $white;

  min-width: 1.5em;
  padding: 0 0.5em;
  height: 1.5em;
  border-radius: 1.5em;
  text-align: center;

  overflow: hidden;

  .favorites__comments--dark-mode & {
    background-color: $white;
    color: $text; } }
