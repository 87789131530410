$sprocket-width: 3em;
$sprocket-container-width: $sprocket-width * 10;
$sprocket-container-width-mobile: $sprocket-width * 4;

.sprockets {
  display: flex;

  position: relative; }

.sprockets__actual {
  width: $sprocket-container-width;
  overflow: hidden;

  position: relative;
  height: 2.5em;

  margin: 0 1em;

  @media ( max-width: $tablet - 1px ) {
    width: $sprocket-container-width-mobile;
    margin: 0 0.5em; }

  &::before {
    content: '';
    position: absolute;
    width: $sprocket-width;
    height: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(to right, $dark, transparentize($dark, 1));
    z-index: 1;
    pointer-events: none; }

  &::after {
    content: '';
    position: absolute;
    width: $sprocket-width;
    height: 100%;
    right: 0;
    top: 0;
    background: linear-gradient(to left, $dark, transparentize($dark, 1));
    z-index: 1;
    pointer-events: none; } }

.sprockets__sprocket {
  position: absolute;
  top: 0;

  width: $sprocket-width;
  left: ($sprocket-container-width - $sprocket-width) / 2;

  @media ( max-width: $tablet - 1px ) {
    left: ($sprocket-container-width-mobile - $sprocket-width) / 2; }

  transition: transform $speed;

  font-weight: bold;
  font-size: 1em; }

.sprockets__side-button {
  width: $sprocket-width * 1.5;

  font-weight: bold;
  font-size: 1em;

  @media ( max-width: 450px ) {
    width: $sprocket-width * 0.75; } }

.sprockets__carets {
  position: absolute;
  top: 0;
  left: 50%;
  width: 0;
  height: 100%; }

.sprockets__caret-top {
  position: absolute;
  top: -0.5em;
  left: -1em;
  width: 2em;
  height: 1em;

  line-height: 1em;
  text-align: center;

  pointer-events: none;

  @media ( max-width: $tablet - 1px ) {
    top: -0.3em; } }

.sprockets__caret-bottom {
  position: absolute;
  bottom: -0.5em;
  left: -1em;
  width: 2em;
  height: 1em;

  line-height: 1em;
  text-align: center;

  pointer-events: none;

  @media ( max-width: $tablet - 1px ) {
    bottom: -0.3em; } }
