.comment {
  width: 100%;
  margin-top: 1em;
  padding: 1em;

  display: flex;

  background-color: lighten($dark, 6%);
  border-radius: $radius; }

.comment__avatar-container {
  flex-shrink: 0;
  margin-right: 0.5em;
  padding-top: 0.25em; }

.comment__avatar {
  width: 2.5em;
  border-radius: 50%; }

.comment__avatar-placeholder {
  font-size: 2.5em; }

.comment__main {
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;

  .comment__header {
    width: 100%; }

  .comment__content {
    margin-bottom: .75em;
    width: 100%; }

  textarea {
    background-color: rgba(0, 0, 0, 0.3);
    border: 0;
    border-radius: 0;
    font-weight: 700;
    font-size: inherit;
    margin-bottom: .75em;
    outline: none;
    resize: none;
    width: 100%;
    padding: .75em; } }

.comment__menu {
  position: relative;

  .comment__trigger {
    color: white;
    cursor: pointer;
    padding: calc(0.5em - #{$button-border-width}) 1em; }

  .comment__menu-items {
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
    right: 0;
    max-height: 0;
    overflow: hidden;
    transition: max-height .1s;
    z-index: 99;

    &.open {
      max-height: 9em; }

    .button .icon:last-child:not(:first-child) {
      margin-left: .75em;
      margin-right: 0; } } }

.comment__name-row {
  display: flex;
  padding-bottom: .5em;
  flex-direction: column;
  align-items: flex-start; }

.comment__name {
  font-weight: bold;

  @media ( max-width: $tablet - 1px ) {
    display: flex;
    align-items: center; } }

.comment__timestamp {
  color: $grey;
  font-size: 0.75em;

  .comment-section--dark-mode & {
    color: $grey-light; } }
