.comment-section {
  display: flex;
  flex-direction: column;
  width: 100%;

  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden; }

.comment-section__comments-container {
  flex-grow: 1;
  overflow: hidden;

  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 2em;

    background: linear-gradient(to bottom, $white, transparentize($white, 1));

    .comment-section--dark-mode & {
      background: linear-gradient(to bottom, $dark, transparentize($dark, 1)); } } }

.comment-section__comments {
  overflow-y: scroll;
  width: 100%;
  height: 100%;

  overscroll-behavior: contain;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none; }

  user-select: none;

  display: flex;
  flex-direction: column-reverse;

  padding-right: 1em;
  padding-top: 1em;
  padding-bottom: 0.5em;

  &.comment-section__comments--no-padding {
    padding-right: 0; } }

.comment-section__filler {
  flex-grow: 1; }

.comment-section__new-comment {
  padding-top: 0.5em;
  // border-top: 1px solid $border

  z-index: 1; }

.comment-section__comment-field {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  resize: none;

  &, &[rows], &:not([rows]) {
    min-height: 0; }

  &.comment-section__comment-field--dark-mode {
    background-color: transparent;
    color: $white;

    &:focus {
      border-color: $white; }

    &::placeholder {
      color: $grey-light; } } }

.comment-section__send-button {
  height: 2.97em; }

.comment-section__nocomment {
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: $text-light;

  .comment-section--dark-mode & {
    color: $grey-light; }

  margin: 1em 0; }

.comment-section__nocomment-suggestion {
  font-size: 0.75em; }
