.upload-screen__hero-body {
  display: flex;
  flex-direction: column;

  @media ( max-width: $tablet - 1px ) {
    padding-left: 1em;
    padding-right: 1em; } }

.upload-screen__upload-area {
  border: 0.125em dashed $text;
  flex-grow: 1;
  padding: 2em;
  border-radius: 1em;

  display: flex;
  flex-direction: column;

  @media ( max-width: $tablet - 1px ) {
    padding: 0.5em;
    border-radius: 0.5em; } }

.upload-screen__upload-button {
  padding: 2em;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  flex-grow: 1; }

.upload-screen__photos {
  display: flex;
  flex-wrap: wrap; }

.upload-screen__photo-wrapper {
  height: 8.25rem;
  width: 8.25rem;

  @media ( max-width: $tablet - 1px ) {
    height: 7.5em;
    width: 7.5em; }

  margin: 0.25rem;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative; }

.upload-screen__photo-border {
  border: 1px solid $border;
  padding: 0.25em;
  transition: border-color .2s;
  background-color: $white; }

.upload-screen__photo {
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative; }

.upload-screen__photo-border--hidden {
  border-color: transparent; }

.upload-screen__upload-row {
  margin-top: 1rem;

  display: flex;
  align-items: center; }

.upload-screen__progress-bar-area {
  flex-grow: 1;
  flex-shrink: 1;

  margin-right: 1rem; }

.upload-screen__photo-overlay {
  background: transparentize($text, 0.125); // ND8
  color: $white;

  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border-radius: 0.5rem; }

.upload-screen__photo-overlay-progress {
  padding: 0.5em 1em 0;

  & > progress {
    border: 1px solid $white;

    background-color: transparent !important;

    &:indeterminate {
      background-color: transparent !important;
      background-image: linear-gradient(to right, $white 30%, transparent 30%) !important; } } }

.upload-screen__delete-overlay {
  background: transparentize($red, 0.3);
  color: $white;

  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border-radius: 0.5rem; }
