.login-screen__container {
  margin: 0 auto;
  max-width: 84.5rem; }

.login-screen__email {
  position: relative;

  &::before {
    content: 'enter your email';
    display: block;
    position: absolute;
    font-size: 1.25rem;
    font-weight: bold;
    left: 0;
    top: -0.5rem; } }


@media screen and ( max-width: 768px ) {
  .login-screen__title,
  .login-screen__subtitle {
    line-height: 1.5 !important; }

  .login-screen__title {
    margin-left: 0 !important; } }

@media screen and ( max-height: 640px ) {
  .login-screen__body {
    padding: 1.5rem; }

  .login-screen__main-logo {
    .fa-6x {
      font-size: 4rem; }

    .login-screen__title {
      font-size: 1.25rem; } }

  .login-screen__message {
    font-size: 1rem !important; }

  .login-screen__email {

    input {
      font-size: 1rem !important; }

    &::before {
     font-size: 1rem; }

    + div {
      button {
        font-size: 1rem  !important; } } }

  .login-screen__forget {
    font-size: 1rem !important; }

  .login-screen__container {
    .mt-5 {
      margin-top: 0 !important; }

    .mt-6 {
      margin-top: 2.3rem !important; } } }
