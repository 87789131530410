.reorder {
  position: relative; }

.reorder__button--hidden {
  display: none; }

.reorder__item--reordering {
  position: absolute;
  cursor: grab;
  user-select: none;

  &:not(.reorder__item--other-grabbed) {
    touch-action: none; } }

.reorder__item--animated {
  transition: transform 0.2s; }

.reorder__item--grabbed {
  z-index: 1;
  cursor: grabbing; }
