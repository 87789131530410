.actual-image {
  background-color: $dark;
  box-shadow: 0 0 3em inset rgba(0, 0, 0, 0.6);

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  &.actual-image--no-loading-icon {
    box-shadow: none; } }

.actual-image__loading-icon {
  filter: drop-shadow(0 0 0.25rem $black);
  color: $dark;
  font-size: 4em; }

.actual-image__image {
  position: absolute;
  width: 100%;
  height: 100%; }
