.use-modal__header {
  padding: 0.5em 1em; }

.use-modal__title {
  font-weight: 500;
  font-size: 1.5em; }

.use-modal__body {
  padding: 1em;
 }  // border: 1px solid $border

.use-modal__buttons {
  padding: 0.5em 1em 1em;

  display: flex;
  justify-content: flex-end; }

.use-modal__button {
  border-radius: $radius !important;

  &:not(:last-child) {
    margin-right: 0.5em; } }
