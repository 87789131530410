.mobile-image {
  flex-grow: 1;
  align-self: stretch;

  position: relative;

  touch-action: none; }

.mobile-image__first-page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  transition: top $speed * 2, transform $speed * 2;

  .mobile-image--swiping & {
    transition: top $speed * 2; }

  .mobile-image--comments-open & {
    top: -100%; } }

.mobile-image__image-area {
  flex-grow: 1;
  flex-shrink: 1;

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; }

.mobile-image__favorites {
  margin: 0.5em 4em 0;
  cursor: pointer;

  @media ( min-width: $tablet ) {
    margin: 0.5em 6em 0; } }

.mobile-image__comment-prompt {
  height: 2rem;
  font-size: 0.75em;
  font-weight: bold;
  padding-bottom: 0.5rem 0;

  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer; }

.mobile-image__second-page {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  transition: top $speed * 2, transform $speed * 2;

  .mobile-image--swiping & {
    transition: top $speed * 2; }

  padding: 0 2em;

  @media ( min-width: $tablet ) {
    padding: 0 6em; }

  .mobile-image--comments-open & {
    top: 0; } }

.mobile-image__title {
  padding: 0 2em;
  text-align: center;
  font-size: 0.75em;
  font-weight: bold;
  // height: 2rem
  display: flex;
  justify-content: center;
  align-items: center; }

.mobile-image__author,
.mobile-image__date {
  font-size: 0.75em; }
