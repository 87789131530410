.spaceship-user {
  background-color: lighten($dark, 6%);
  border-radius: $radius;

  .media-left {
    overflow: visible; }

  .media-right {
    .spaceship-user__settings-trigger {
      color: white;
      cursor: pointer;
      padding: 0 .5em .9em; }

    .spaceship-user__settings-menu-items {
      background-color: rgba(0, 0, 0, 0.8);
      position: absolute;
      right: 0;
      max-height: 0;
      overflow: hidden;
      transition: max-height .1s;
      z-index: 9;

      &.open {
        max-height: 9em; }

      .button .icon:last-child:not(:first-child) {
        margin-left: .75em;
        margin-right: 0; } } }

  .content {
    max-height: 0;
    margin-top: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height .1s, margin-top .1s, opacity .1s;

    &.active {
      max-height: 9em;
      margin-top: 1.5rem;
      opacity: 1; }

    .spaceship-user__permission-selector {
      margin-right: .75rem;

      &.select:not(.is-multiple):not(.is-loading)::after {
        border-color: white; }

      select {
        background-color: lighten($dark, 6%);
        color: white; } } } }

.resend-invitation-button {
  &::after {
   display: none !important; }

  &.button.is-loading {
    color: white !important;
    pointer-events: all !important; } }
