.add-passenger-form {
  background-color: lighten($dark, 6%);
  padding: 0;
  margin-bottom: 0;
  max-height: 0;
  opacity: 0;
  transition: opacity .1s, margin-bottom .1s .1s, max-height .1s .1s, padding .1s .1s;
  overflow: hidden;

  &.activated {
    opacity: 1;
    max-height: 30em;
    margin-bottom: 3rem;
    padding: 1rem;
    transition: opacity .1s, margin-bottom .1s, max-height .1s, padding .1s; }

  input, select {
    background-color: rgba(0, 0, 0, 0.3);
    color: $white;

    &:focus {
      border-color: $white; }

    &::placeholder {
      color: $grey-lighter; } }

  .select:not(.is-multiple):not(.is-loading)::after {
    border-color: white; } }
